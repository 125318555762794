// THIS FILE IS AUTOGENERATED. PLEASE DO NOT EDIT IT
import * as Types from "./operations";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export const ClientProfileViewFragmentDoc = gql`
  fragment ClientProfileView on ClientProfile {
    name
    surname
    fathersName
    birthdate
    snils
    phoneNumber
    email
    addresses {
      fullAddress
      startDate
      endDate
    }
  }
`;
export const AppealInfoFragmentDoc = gql`
  fragment AppealInfo on AppealInfo {
    appealTypeName
    responseDate
  }
`;
export const AppealConclusionInfoItemFragmentDoc = gql`
  fragment AppealConclusionInfoItem on AppealConclusionInfo {
    id
    name
    conclusionFile {
      fileName
      file
    }
    responseDate
    isActual
  }
`;
export const AppealInfoItemFragmentDoc = gql`
  fragment AppealInfoItem on AppealInfo {
    appealTypeName
    id
    isActual
    responseDate
  }
`;
export const ClientProfileDocument = gql`
  query ClientProfile {
    clientProfile {
      ...ClientProfileView
    }
  }
  ${ClientProfileViewFragmentDoc}
`;

/**
 * __useClientProfileQuery__
 *
 * To run a query within a React component, call `useClientProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ClientProfileQuery,
    Types.ClientProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ClientProfileQuery,
    Types.ClientProfileQueryVariables
  >(ClientProfileDocument, options);
}
export function useClientProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ClientProfileQuery,
    Types.ClientProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ClientProfileQuery,
    Types.ClientProfileQueryVariables
  >(ClientProfileDocument, options);
}
export type ClientProfileQueryHookResult = ReturnType<
  typeof useClientProfileQuery
>;
export type ClientProfileLazyQueryHookResult = ReturnType<
  typeof useClientProfileLazyQuery
>;
export type ClientProfileQueryResult = Apollo.QueryResult<
  Types.ClientProfileQuery,
  Types.ClientProfileQueryVariables
>;
export const AppealInfoDocument = gql`
  query AppealInfo($appealId: Int!) {
    appealInfo(appealId: $appealId) {
      ...AppealInfo
    }
  }
  ${AppealInfoFragmentDoc}
`;

/**
 * __useAppealInfoQuery__
 *
 * To run a query within a React component, call `useAppealInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppealInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppealInfoQuery({
 *   variables: {
 *      appealId: // value for 'appealId'
 *   },
 * });
 */
export function useAppealInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AppealInfoQuery,
    Types.AppealInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AppealInfoQuery, Types.AppealInfoQueryVariables>(
    AppealInfoDocument,
    options
  );
}
export function useAppealInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AppealInfoQuery,
    Types.AppealInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.AppealInfoQuery,
    Types.AppealInfoQueryVariables
  >(AppealInfoDocument, options);
}
export type AppealInfoQueryHookResult = ReturnType<typeof useAppealInfoQuery>;
export type AppealInfoLazyQueryHookResult = ReturnType<
  typeof useAppealInfoLazyQuery
>;
export type AppealInfoQueryResult = Apollo.QueryResult<
  Types.AppealInfoQuery,
  Types.AppealInfoQueryVariables
>;
export const AppealConclusionsInfosDocument = gql`
  query AppealConclusionsInfos(
    $id: Int!
    $skip: Int
    $take: Int
    $order: [AppealConclusionInfoSortInput!]
    $where: AppealConclusionInfoOutputFilterInput
  ) {
    appealConclusions(
      id: $id
      skip: $skip
      take: $take
      order: $order
      where: $where
    ) {
      totalCount
      items {
        ...AppealConclusionInfoItem
      }
    }
  }
  ${AppealConclusionInfoItemFragmentDoc}
`;

/**
 * __useAppealConclusionsInfosQuery__
 *
 * To run a query within a React component, call `useAppealConclusionsInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppealConclusionsInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppealConclusionsInfosQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAppealConclusionsInfosQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AppealConclusionsInfosQuery,
    Types.AppealConclusionsInfosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.AppealConclusionsInfosQuery,
    Types.AppealConclusionsInfosQueryVariables
  >(AppealConclusionsInfosDocument, options);
}
export function useAppealConclusionsInfosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AppealConclusionsInfosQuery,
    Types.AppealConclusionsInfosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.AppealConclusionsInfosQuery,
    Types.AppealConclusionsInfosQueryVariables
  >(AppealConclusionsInfosDocument, options);
}
export type AppealConclusionsInfosQueryHookResult = ReturnType<
  typeof useAppealConclusionsInfosQuery
>;
export type AppealConclusionsInfosLazyQueryHookResult = ReturnType<
  typeof useAppealConclusionsInfosLazyQuery
>;
export type AppealConclusionsInfosQueryResult = Apollo.QueryResult<
  Types.AppealConclusionsInfosQuery,
  Types.AppealConclusionsInfosQueryVariables
>;
export const AppealsInfoDocument = gql`
  query AppealsInfo(
    $skip: Int
    $take: Int
    $order: [AppealInfoSortInput!]
    $where: AppealInfoOutputFilterInput
  ) {
    appealsInfo(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        ...AppealInfoItem
      }
    }
  }
  ${AppealInfoItemFragmentDoc}
`;

/**
 * __useAppealsInfoQuery__
 *
 * To run a query within a React component, call `useAppealsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppealsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppealsInfoQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAppealsInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.AppealsInfoQuery,
    Types.AppealsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.AppealsInfoQuery,
    Types.AppealsInfoQueryVariables
  >(AppealsInfoDocument, options);
}
export function useAppealsInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AppealsInfoQuery,
    Types.AppealsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.AppealsInfoQuery,
    Types.AppealsInfoQueryVariables
  >(AppealsInfoDocument, options);
}
export type AppealsInfoQueryHookResult = ReturnType<typeof useAppealsInfoQuery>;
export type AppealsInfoLazyQueryHookResult = ReturnType<
  typeof useAppealsInfoLazyQuery
>;
export type AppealsInfoQueryResult = Apollo.QueryResult<
  Types.AppealsInfoQuery,
  Types.AppealsInfoQueryVariables
>;
