import { NavLink as NavLink } from "react-router-dom";
import React, { useContext, useState } from "react";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { IconButton } from "@genome-frontend/uikit/build/lib/uikit/IconButton/IconButton";
import { SignOutOutlineIcon } from "@genome-frontend/uikit/build/lib/icons/SignOutOutlineIcon";
import { AuthContext, AuthStateEnum } from "auth/AuthContextProvider";
import { PersonOutlineIcon } from "icons/PersonOutlineIcon";
import { useAuth } from "auth/useAuth";
import { AccessPermissions } from "types/AccessPermissions";
import { TokenHelper } from "TokenHelper";
import { paths } from "router/paths";
import style from "./style.module.scss";
import { useApolloClient } from "@apollo/client";
import { ApolloHelper } from "../../ApolloHelper";

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const apolloClient = useApolloClient();

  const { setState } = useContext(AuthContext);
  const { hasAccessPermission } = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = async () => {
    setState(AuthStateEnum.UNAUTHORIZED);
    handleClose();
    localStorage.removeItem("login");
    TokenHelper.logout();
    await ApolloHelper.onLogout(apolloClient);
  };

  const open = Boolean(anchorEl);

  const userFio = TokenHelper.getCurrentUserFio();

  return (
    <>
      <Box
        sx={{
          p: 2,
          backgroundColor: "#0D424E",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack spacing={3} direction="row">
          {hasAccessPermission(AccessPermissions.ClientsMedical) && (
            <NavLink
              className={({ isActive }) =>
                isActive ? style.active : style.normal
              }
              to={paths.clientServices()}
            >
              Мои услуги
            </NavLink>
          )}
          {hasAccessPermission(AccessPermissions.ClientsMedical) && (
            <NavLink
              className={({ isActive }) =>
                isActive ? style.active : style.normal
              }
              to={paths.profile()}
            >
              Профиль
            </NavLink>
          )}
        </Stack>

        <Box sx={{ display: "flex", ml: "auto" }}>
          <Stack sx={{ ml: 15 }} spacing={1} direction="row">
            <Typography noWrap sx={{ marginTop: 1, color: "white" }}>
              {userFio}
            </Typography>
            <IconButton onClick={handleClick}>
              <PersonOutlineIcon />
            </IconButton>
          </Stack>
        </Box>
      </Box>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClickLogout}>
          <ListItemIcon>
            <SignOutOutlineIcon />
          </ListItemIcon>
          <ListItemText>Выйти</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
